import { render, staticRenderFns } from "./addEditPage.vue?vue&type=template&id=41630884&scoped=true&"
import script from "./addEditPage.vue?vue&type=script&lang=js&"
export * from "./addEditPage.vue?vue&type=script&lang=js&"
import style0 from "./addEditPage.vue?vue&type=style&index=0&id=41630884&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "41630884",
  null
  
)

export default component.exports